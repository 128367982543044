import(/* webpackMode: "eager", webpackExports: ["default"] */ "/data01/virt128836/domeenid/www.kursused.ebs.ee/test.kursused/node_modules/next-intl/dist/esm/navigation/shared/BaseLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/data01/virt128836/domeenid/www.kursused.ebs.ee/test.kursused/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager" */ "/data01/virt128836/domeenid/www.kursused.ebs.ee/test.kursused/node_modules/next/font/google/target.css?{\"path\":\"src/app/[locale]/layout.tsx\",\"import\":\"Mulish\",\"arguments\":[{\"subsets\":[\"latin\"],\"display\":\"block\",\"variable\":\"--font-mulish\",\"preload\":true}],\"variableName\":\"mulish\"}");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/data01/virt128836/domeenid/www.kursused.ebs.ee/test.kursused/src/app/_components/CookieConsent/index.tsx");
;
import(/* webpackMode: "eager" */ "/data01/virt128836/domeenid/www.kursused.ebs.ee/test.kursused/src/app/_components/Footer/index.module.scss");
;
import(/* webpackMode: "eager" */ "/data01/virt128836/domeenid/www.kursused.ebs.ee/test.kursused/node_modules/vanilla-cookieconsent/dist/cookieconsent.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/data01/virt128836/domeenid/www.kursused.ebs.ee/test.kursused/src/app/_components/Header/HeaderNav/index.tsx");
;
import(/* webpackMode: "eager" */ "/data01/virt128836/domeenid/www.kursused.ebs.ee/test.kursused/src/app/_css/app.scss");
;
import(/* webpackMode: "eager" */ "/data01/virt128836/domeenid/www.kursused.ebs.ee/test.kursused/src/app/_css/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["Providers"] */ "/data01/virt128836/domeenid/www.kursused.ebs.ee/test.kursused/src/app/_providers/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Toaster"] */ "/data01/virt128836/domeenid/www.kursused.ebs.ee/test.kursused/src/app/@shadcn/components/ui/toaster.tsx");
